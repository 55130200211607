@import url("//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.0.3/cookieconsent.min.css");
@import url("//cdnjs.cloudflare.com/ajax/libs/ekko-lightbox/5.3.0/ekko-lightbox.css");
@import "../../../node_modules/font-awesome/scss/font-awesome";

html, body {
    min-height: 100%;
}

.mt0 {
    margin-top: 0 !important;
}

.mt5 {
    margin-top: 5px !important;
}

.mt15 {
    margin-top: 15px !important;
}

.mt30 {
    margin-top: 30px !important;
}

.mb0 {
    margin-bottom: 0 !important;
}

.mb5 {
    margin-bottom: 5px !important;
}

.mb15 {
    margin-bottom: 15px !important;
}

.mb30 {
    margin-bottom: 30px !important;
}

.ml0 {
    margin-left: 0 !important;
}

.ml5 {
    margin-left: 5px !important;
}

.ml15 {
    margin-left: 15px !important;
}

.mr0 {
    margin-right: 0 !important;
}

.mr5 {
    margin-right: 5px !important;
}

.mr15 {
    margin-right: 15px !important;
}

// typography
.monospace {
    font-family: $font-family-monospace;
}

// clean layout
.clean {
    background-color: $brand-primary;
}

#main-content-clean {
    margin-top: $grid-gutter-width * 3;
    margin-bottom: $grid-gutter-width * 2;

    > .container {
        background: #fff;
        padding-top: 30px;
        padding-bottom: 60px;
        border-radius: $grid-gutter-width / 2;

        &.message-container {
            background: transparent;
            padding-bottom: 30px;
            border-radius: 0;
        }
    }

    #order-review-logo {
        margin-bottom: 30px;
    }
    
    #cardholder-name {
        font-family: sans-serif;
        color: #000;
        font-size: 14px;
        background-color: #fff;
        padding: 8px 12px;
        border-radius: 4px;
        border: 1px solid #ccc;
        box-shadow: 0 1px 3px 0 #afb7bf;
        transition: box-shadow 150ms ease;
        
        &::placeholder {
            color: #767676;
        }
    }
}

// admin nav
#admin-nav {
    position: relative;
    z-index: 1001;
    margin-bottom: 0;
    
    body.clean & {
        margin-bottom: 20px;
    }

    li {
        display: none;
        
        &.display-frontend {
            display: block;
        }
    }
}

// currency menu
#currency-menu {
    display: none !important;

    .home &, .category &, .product &, .basket &, .account &, .page & {
        display: block !important;
    }
}

// homepage product tabs
#fp-product-tabs {
    margin-top: $grid-gutter-width * 2;
    padding-top: $grid-gutter-width * 2;
    border-top: 1px solid $gray-lighter;
    margin-bottom: $grid-gutter-width * 2;

    .nav {
        font-weight: 700;
        font-size: 14px;
        margin-bottom: $grid-gutter-width;
    }
}

// product group grids
.product-grid {
    display: flex;
    flex-wrap: wrap;
    
    .product-grid-product {
        display: flex;
        
        .panel {
            width: 100%;
        }
    }
}

// product images
.product-image-wrapper {
    img {
        @extend .img-responsive;
    }
}

.product-main-image {
    &-1 {
        @extend .col-xs-12;
        border: 0;
        border-radius: 0;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.product-thumbnails {
    margin-top: 20px;

    img {
        @extend .thumbnail;
    }
}

.ekko-lightbox-nav-overlay {
    a {
        &:link, &:visited {
            color: #FFF;
            text-shadow: 0 1px 2px #111;
        }
    }
}

// autocomplete searching
#search-form, #error-search {
    .input-group-btn {
        vertical-align: top;
    }
    
    .aa-dropdown-menu {
        width: 100%;
        border-radius: 3px;
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid transparent;
        box-shadow: 0 1px 3px 0 #cfd7df;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
        border: 0;
    }

    .autocomplete-search-result {
        a {
            display: block;
            border-bottom: 1px solid #cfd7df;
            padding: 8px 12px;
        }

        &-name, &-id, &-price {
            display: block;
        }
    }
}

// instant searching
#search-results-wrapper {
    display: none;
    position: absolute;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 20px;
    border-bottom: 5px solid;
    background-color: #FFF;
    transition: background 1s;
    
    #search-results-input input {
        font-size: 3em;
        border: 0;
        background: transparent;
        height: auto;
        box-shadow: none;
        padding-left: 0;
        padding-right: 0;
    }
    
    .search-results {
        display: flex;
        flex-wrap: wrap;
        
        .product-grid-product {
            display: flex;

            .panel {
                width: 100%;
            }
        }
    }
    
    .search-close {
        position: absolute;
        top: 20px;
        right: 20px;
    }
    
    .algolia-logo {
        position: absolute;
        bottom: 20px;
        right: 20px;
    }
}

// basket
#basket-table .btn-link {
    padding: 0;
}

// basket & checkout
#basket footer.buttons, #checkout-info-submit {
    text-align: center;
    
    a.btn:first-child {
        margin-bottom: $grid-gutter-width / 2;
        
        @media(min-width: $screen-md-min){
            margin-bottom: 0;
            margin-right: $grid-gutter-width / 2;
        }
    }

    a.btn:last-child, input.btn {
        margin-bottom: $grid-gutter-width / 2;
        
        @media(min-width: $screen-md-min){
            margin-bottom: 0;
            margin-left: $grid-gutter-width / 2;
        }
    }
}

// checkout
.initially-hidden {
    display: none;
}

.form-control {
    background-color: #fff;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #cfd7df;
    box-shadow: 0 1px 3px 0 #cfd7df;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

// checkout review: stripe
.StripeElement {
    background-color: #fff;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #cfd7df;
    box-shadow: 0 1px 3px 0 #afb7bf;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement--focus, .form-control:focus {
    box-shadow: 0 1px 3px 0 #afb7bf;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

#card-element {
    margin-bottom: $grid-gutter-width / 2;
}

// checkout review: worldpay json
.worldpay-json-payment-form {
    text-align: center;
    
    iframe {
        max-width: 100%;
    }
}

// checkout review: terms checkbox
#review-terms-checkbox {
    margin-left: 0;
}

// new user notifications
#new-user-notification-dot {
    font-size: 0.5em;
    color: $brand-primary;
}

#new-user-notification-badge {
    background: $brand-primary;
    font-size: 0.75em;
}

// tables with buttons in
#manage-users, #giftcards-table-wrapper {
    table {
        td {
            vertical-align: middle;
        }
    }
}

// user address default forms
#address-list .default-address-form {
    display: inline-block;
}

// onetrust cookie compliance
.one-trust-preference-centre {
    text-decoration: underline;
    padding: 0;

    .main-footer, .mainfooter & {
        color: #fff;
        text-decoration: none;

        &:hover, &:focus {
            color: #fff;
            text-decoration: underline;
        }
    }
}

.ot-sdk-cookie-policy {
    font-size: 1em !important;
}